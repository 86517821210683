import "selectize/dist/js/selectize.js";
import "selectize/dist/css/selectize.css";

import instantsearch from "instantsearch.js/es";
import { connectAutocomplete } from "instantsearch.js/es/connectors";
import { configure } from "instantsearch.js/es/widgets";

const algoliasearch = require("algoliasearch");

export default function algoliaSearcher(
  algoliaKey,
  algoliaSecret,
  algoliaIndex,
  algoliaValueField,
  selectID
) {
  const searchClient = algoliasearch(`${algoliaKey}`, `${algoliaSecret}`);

  const autocomplete = connectAutocomplete(
    ({ indices, refine, widgetParams }, isFirstRendering) => {
      const { container, onSelectChange } = widgetParams;

      if (isFirstRendering) {
        container.html(`<select id="${selectID}" name="${selectID}"></select>`);

        $(`#${selectID}`).selectize({
          options: [],
          valueField: `${algoliaValueField}`,
          labelField: "name",
          highlight: false,
          maxItems: 1,
          onType: refine,
          onBlur() {
            refine(this.getValue());
          },
          score() {
            return function () {
              return 1;
            };
          },
          onChange(value) {
            onSelectChange(value);
            refine(value);
          },
        });
        return;
      }

      const [select] = $(`#${selectID}`);
      const written_text = $(`input[id='${selectID}-selectized']`)[0].value;

      if (written_text.length > 2) {
        indices.forEach((index) => {
          select.selectize.clearOptions();
          index.results.hits.forEach((h) => select.selectize.addOption(h));
          select.selectize.refreshOptions(select.selectize.isOpen);
        });
      }
    }
  );

  const search = instantsearch({
    indexName: `${algoliaIndex}`,
    searchClient,
  });

  const suggestions = instantsearch({
    indexName: `${algoliaIndex}`,
    searchClient,
  });

  suggestions.addWidgets([
    configure({
      hitsPerPage: 6,
    }),
    autocomplete({
      container: $(`#${selectID}`),
      onSelectChange(value) {
        search.helper.setQuery(value).search();
      },
    }),
  ]);

  suggestions.start();
  search.start();
}
