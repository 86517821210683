export default function sponsoringBudgetCalculator(sports = {}) {
  $("#sponsoring_budget").prop("readonly", true);
  for (const [sport, cost] of Object.entries(sports)) {
    $(`#sponsored_club_count_${sport}`).attr({
      min: 0,
      oninput: "validity.valid || (value = '')",
    });
    $(`#sponsored_club_count_${sport}`).on("keyup change", function () {
      calculateTotalAmount(sports);
    });
  }

  function calculateTotalAmount(sports) {
    let totalAmount = 0;
    for (const [sport, cost] of Object.entries(sports)) {
      let sportCount = $(`#sponsored_club_count_${sport}`).val() || 0;
      let sportAmount = parseInt(sportCount, 10) * cost;
      totalAmount += sportAmount;
    }
    $("#sponsoring_budget").val(totalAmount);
  }
}
